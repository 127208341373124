import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "../components/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText, filterOutDocsWithoutSlugs } from "../lib/helpers";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      previewImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawContent(resolveReferences: { maxDepth: 10 })
      #content {
      #  ...SanityHero
      #  ...SanityTextSection
      #  ...SanityTwoColumn
      #}
    }
    allProjectSlugs: sanityArchiveSettings {
      slugs: projectList {
        ... on SanityProject {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const ProjectTemplate = (props) => {
  const { data, errors } = props;
  const project = data && data.project;
  const { slugs } = data.allProjectSlugs;
  const filtered = slugs.filter(filterOutDocsWithoutSlugs);

  const adjacentSlugs = filtered.reduce((results, slug, index) => {
    if (slug.id == project.id) {
      const prevSlug = filtered[index - 1] ? filtered[index - 1] : filtered[filtered.length - 1];
      const nextSlug = filtered[index + 1] ? filtered[index + 1] : filtered[0];

      results.push({ previous: prevSlug, next: nextSlug });
    }
    return results;
  }, []);

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {project && (
        <SEO
          title={project.title || "Untitled"}
          description={toPlainText(project._rawExcerpt)}
          image={project.previewImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <Project {...project} adjacentSlugs={adjacentSlugs[0]} />
    </Layout>
  );
};

export default ProjectTemplate;
